@import 'variables';

@font-face {
  font-family: Manrope;
  src: url(./Manrope-VariableFont_wght.ttf) format('truetype');
}

$text-color: #0d2344;

body {
  background-color: #f7f8ff;
  margin: 0;
  color: $text-color !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-table-wrapper {
  box-shadow: rgb(21 0 85 / 5%) 0px 10px 10px 0px;
}

.ant-table-row {
  cursor: pointer;
}

.ant-tabs-top > .ant-tabs-nav:before {
  content: none;
}

.anticon-eye,
.anticon-edit {
  color: #1890ff;
}

.anticon-delete {
  color: red;
}

.ant-select-dropdown,
.ant-modal-mask,
.ant-modal-wrap,
.ant-message {
  z-index: 100001;
}
