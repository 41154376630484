.pdf-editor-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 86px);
  overflow: scroll;

  &__bar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: sticky;
    top: 0;
    width: 100%;
    height: 40px;
    background-color: white;
    z-index: 9999;
  }

  &__pages {
    padding: var(--pdf-editor-padding);
  }
}
