.pdf-document-wrapper {
  display: flex;
  justify-content: center;

  .react-pdf__Page {
    margin-bottom: 1rem;
    background-color: initial !important;
    display: flex;
    align-items: center;
    justify-content: center;

    canvas {
      box-shadow: 0 0 4px 1px gray;
    }
  }

  .page-skeleton {
    width: 1000px;
    height: 1414px;
    box-shadow: 0 0 4px 1px gray;
    padding: 100px 50px;
  }
}
